<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="130px">
				<el-row class="flex-1">
					<el-col :span="7">
						<el-form-item label="用户账号：" placeholder="用户ID/账号/手机/姓名"><el-input v-model="searchParm.accountNo"></el-input></el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="所在城市：">
							<el-select v-model="searchParm.city" clearable :popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="(item, i) in citys" :label="item" :value="item"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="订单时间：">
							<el-col :span="10">
								<el-date-picker v-model="searchParm.regTimeStart" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择日期"></el-date-picker>
							</el-col>
							<el-col :span="2" style="text-align: center;"><span>-</span></el-col>
							<el-col :span="10">
								<el-date-picker v-model="searchParm.regTimeEnd" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择日期"></el-date-picker>
							</el-col>
						</el-form-item>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<el-tabs type="border-card" class="tabs" @tab-click="tabClick" style="flex:1">
			<el-tab-pane :label="item.name" :orderStatus="item.orderStatus" v-for="item of tabs" style="text-align:center;">
				<el-card v-for="(record, i) in data.records" :body-style="{ padding: 0 }" style="margin-bottom: 5px;text-align:left;">
					<div slot="header" class="card-header">
						<span class="orderTime">下单时间：{{ record.orderTime }}</span>
						<span class="orderNo">订单编号：{{ record.orderNo }}</span>
						<!-- <el-button class="chatToMe" type="text">
							<img src="@assets/osImg/order/chatToMe.png"
								style="height: 16px;width: 16px;vertical-align: top ;" />
							<div style="display: inline-block;margin-left: 4px;">和我联系</div>
						</el-button> -->
					</div>
					<div class="card-body flex">
						<div class="flex-1 card-item orderContent">
							<img :src="imgBaseUrl + record.image" />
							<div class="info">
								<div class="info-serviceName">{{ record.serviceName }}</div>
								<div class="info-nameMobile">{{ record.expertName }}·{{ record.mobile }}</div>
							</div>
						</div>
						<!-- <div class="card-item orderStatus" v-for="status of tabs"
							v-if="status.orderStatus==record.orderStatus">
							{{status.name}}
						</div> -->
						<div class="card-item orderStatus">{{ tabObj[record.orderStatus] }}</div>
						<div class="card-item orderPrice">
							<div class="price">￥{{ record.actualPrice }}</div>
							<div class="text">转账汇款</div>
						</div>
						<div class="card-item orderOperation">
							<div>
								<el-button type="primary" size="small" v-if="record.orderStatus == 1" @click="confirmOrder(record)">确认接单</el-button>
								<el-button type="primary" size="small" plain  v-if="record.orderStatus == 1" @click="cancelOrder(record)">取消订单</el-button>
							</div>
							<div><el-button type="text" v-if="record.orderStatus == 1" @click="detailEdit(record)">查看修改订单</el-button></div>

							<div><el-button type="primary" v-if="record.orderStatus == 8" @click="confirmGetPay(record)">确认收到收款</el-button></div>
							<div><el-button type="text" v-if="record.orderStatus == 9" @click="uploadService(record)">上传服务报告</el-button></div>
							<div><el-button type="text" v-if="record.orderStatus != 1" @click="detail(record)">查看详情</el-button></div>
						</div>
					</div>
				</el-card>
				<div class="pagination" v-if="data.records.length > 0">
					<el-pagination
						style="float: right;"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-sizes="[5, 10, 20, 50]"
						:page-size="searchParm.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="data.total"
					></el-pagination>
				</div>
				<el-empty :image-size="240" style="height: 100%;" v-else>
					<img src="@assets/osImg/noneData.png" style="width: 240px;height: 240px;" slot="image" />
					<span slot="description">暂无相关内容</span>
				</el-empty>
			</el-tab-pane>
		</el-tabs>
		<detail :isShow.sync="showDetailDialog" :dialogObj="detailObj"></detail>
		<upload :isShow.sync="showUploadDialog" :dialogObj="uploadObj"></upload>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
// import smFooter from "@c/smFooter.vue"
import configApi from '@config/configApi';

import detail from './detail.vue';
import upload from './upload.vue';
export default {
	components: {
		smCard,
		// smFooter,
		detail,
		upload
	},
	data() {
		return {
			imgBaseUrl: configApi.photoURL,
			data: {
				records: []
			},
			searchParm: {
				channel: 1,
				orderStatus: 0,
				skillType: 1,
				size: 10,
				current: 1
			},
			tabs: [
				{
					name: '全部订单',
					orderStatus: 0
				},
				{
					name: '待确认',
					orderStatus: 1
				},
				{
					name: '待付款',
					orderStatus: 5
				},
				{
					name: '待确认收款',
					orderStatus: 8
				},
				{
					name: '待服务',
					orderStatus: 9
				},
				{
					name: '待验收',
					orderStatus: 11
				},
				// {
				// 	name: '待评价',
				// 	orderStatus: 15
				// },
				{
					name: '已完成',
					orderStatus: 20
				},
				{
					name: '已取消',
					orderStatus: 99
				}
			],
			tabObj: {
				1: '待确认',
				5: '待付款',
				8: '待确认收款',
				9: '待服务',
				11: '待验收',
				15: '待评价',
				20: '已完成',
				99: '已取消'
			},
			showDetailDialog: false,
			showUploadDialog: false,
			detailObj: {},
			uploadObj: {},
			citys: []
		};
	},
	methods: {
		//详情
		detail(record) {
			this.showDetailDialog = true;
			this.detailObj = {
				title: '查看详情',
				record: record,
				isEdit: false
			};
		},
		//上传
		uploadService(record) {
			this.showUploadDialog = true;
			this.uploadObj = {
				title: '上传服务报告',
				record: record
			};
		},
		//查看修改
		detailEdit(record) {
			this.showDetailDialog = true;
			this.detailObj = {
				title: '查看修改订单',
				record: record,
				isEdit: true
			};
		},
		//确认订单
		confirmOrder(record) {
			this.$confirm('请认真查看订单服务需求及价格，避免争议', '是否确认接单', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'info'
			})
				.then(() => {
					this.$post('/shop-api/mall/expert-order/accept', {
						id: record.id
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		},
		//取消订单
		cancelOrder(record) {
			this.$prompt('是否取消接单', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			})
				.then(({ value }) => {
					this.$post('/shop-api/mall/expert-order/cancel', {
						id: record.id,
						cancelReason: value
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		},
		//确认订单收款
		confirmGetPay(record) {
			this.$confirm('请认真查看订单服务需求及价格，避免争议', '是否确认收到收款', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'info'
			})
				.then(() => {
					this.$post('/shop-api/mall/expert-order/pay/confirm', {
						id: record.id
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		},
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		},
		handleSizeChange(val) {
			this.searchParm.size = val;
			this.list();
		},
		tabClick(tab) {
			console.log(tab);
			this.searchParm.orderStatus = tab.$attrs.orderStatus;
			this.list();
		},
		//展示数据
		list() {
			this.$get('/shop-api/mall/expert-order/list', this.searchParm).then(res => {
				if (res.code == 1000) {
					this.data = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		clearSearch() {
			this.searchParm = {
				channel: 1,
				orderStatus: 0,
				skillType: 1,
				size: 10,
				current: 1
			};
		},
		// 获取城市
		getCity() {
			this.citys = [];
			this.$get('/shop-api/mall/expert-order/city/list').then(res => {
				this.citys = res.data;
			});
		}
	},
	mounted() {
		this.getCity();
		this.list();
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';

.tabs {
	flex: 1;
	display: flex;
	flex-direction: column;

	::v-deep .el-tabs__content {
		flex: 1;

		.el-tab-pane {
			text-align: center;
			height: 100%;
		}
	}
}

.card-header {
	position: relative;

	.orderTime {
		margin-right: 20px;
		color: #333333;
		font-size: 14px;
	}

	.orderNo {
		color: #595959;
		font-size: 14px;
	}

	.chatToMe {
		position: absolute;
		right: 420px;
		top: 0;
		padding: 0;
		font-size: 16px;
	}
}

.orderContent {
	border-right: 1px solid #dddddd;
	padding: 16px;
	position: relative;

	img {
		width: 100px;
		height: 100px;
	}

	.info {
		display: inline-block;
		position: absolute;
		left: 127px;
		top: 24px;

		.info-serviceName {
			font-weight: bold;
			margin-bottom: 10px;
			font-size: 16px;
		}

		.info-nameMobile {
			color: #595959;
		}
	}
}

.orderStatus {
	width: 104px;
	border-right: 1px solid #dddddd;
	text-align: center;
	color: #8c8c8c;
	font-size: 16px;
	padding-top: 63px;
}

.orderPrice {
	width: 104px;
	border-right: 1px solid #dddddd;
	text-align: center;
	padding-top: 51px;

	.price {
		font-weight: 500;
		color: #4a99f6;
		font-size: 18px;
	}

	.text {
		font-size: 13px;
		font-weight: 400;
		color: #8c8c8c;
	}
}

.orderOperation {
	width: 319px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.table-header .el-select {
	width: 100px;
	margin-right: 10px;
}

.el-form-item {
	display: flex !important;
}

.el-form-item .el-form-item__content {
	flex: 1;
}

.el-input {
	width: 100% !important;
}

.pagination {
	position: relative;

	> * {
		margin-right: 10px !important;
	}
}
</style>
