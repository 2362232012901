<template>
	<el-upload class="upload-demo" action="" :file-list="fileList" :before-upload="BeforeUpload" :http-request="Upload"
		:on-remove="beforeRemove">
		<el-button size="small" type="primary">点击上传</el-button>
		<!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
	</el-upload>
</template>

<script>
	export default {
		data() {
			return {
				newFile: new FormData(),
				currentFileName: ""
			};
		},
		props: {
			fileList: {
				type: Array,
				default () {
					return [];
				}
			}
		},
		methods: {
			BeforeUpload(file) {
				this.currentFileName = file.name;
				if (file) {
					if(this.newFile.has("file")){
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
				} else {
					return false;
				}
			},
			beforeRemove(file, fileList) {
				this.$emit("update:fileList", fileList);
			},
			Upload() {
				this.$emit("upload", {
					filename: this.currentFileName,
					newFile: this.newFile
				});
			},
		}
	}
</script>

<style>
</style>
